import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title="Contact"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Contact</h1>
                </div>

                <div className={"row"}>
                    <div className={"contact-items"}>
                            <p>An email is the best way to reach us.</p>
                            <h3>contact@histaff.io</h3>
                    </div>
                    { /*
                    <div className={"col-7"}>
                        <p>We take our commitment to our users seriously. If you need our help with your user account, have questions about Phonica, any feedback or are experiencing other difficulties, please do not hesitate to contact us.</p>
                    </div>

                    <div className={"col-5"}>
                        <div className={"contact-items"}>
                            <p>An email is the best way to reach us.</p>
                            <h2>contact@histaff.io</h2>
                        </div>
                    </div>
                    */ }
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
